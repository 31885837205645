/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = async () => {
	// NOTE: Don't polyfill Promise here (Gatsby already includes a Promise polyfill)

	// IntersectionObserver polyfill for gatsby-image (Safari, IE)
	if (typeof window.IntersectionObserver === `undefined`) {
		await import(`intersection-observer`)
		console.info(`👍 IntersectionObserver is polyfilled`)
	}

	// Object-fit/Object-position polyfill for gatsby-image (IE)
	const testImg = document.createElement(`img`)
	if (
		typeof testImg.style.objectFit === `undefined` ||
        typeof testImg.style.objectPosition === `undefined`
	) {
		await import(`object-fit-images`)()
		console.info(`👍 Object-fit/Object-position are polyfilled`)
	}

	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		window.isMobile = true
	}
}

exports.shouldUpdateScroll = ({
	routerProps: { location },
	prevRouterProps,
}) => {
	if ( prevRouterProps ) {
		const { pathname } = location
		const { location: oldLocation } = prevRouterProps
		const { pathname: oldPathname } = oldLocation

		return pathname !== oldPathname
	} 
	return true
    

}